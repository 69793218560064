import { createContext, useContext } from 'react';
export const AuthContext = createContext({
    isLoggedIn: false,
    redirectToSignIn: () => null,
    signInWithAuthCode: () => null,
    signOut: () => null,
});
export const useAuth = () => {
    const ctx = useContext(AuthContext);
    if (!ctx) {
        throw new Error('useAuth must be called within <AuthProvider />');
    }
    return ctx;
};
