import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { IngestLogMessageLevel } from '../../api/data-logs';
export const LogLevelLabel = ({ level, ...props }) => (_jsx(Typography, { ...props, color: mapLevelToColor[level], children: mapLevelToLabel[level] }));
const mapLevelToLabel = {
    [IngestLogMessageLevel.INFO]: 'Information',
    [IngestLogMessageLevel.WARNING]: 'Warning',
    [IngestLogMessageLevel.ERROR]: 'Error',
};
const mapLevelToColor = {
    [IngestLogMessageLevel.INFO]: '#67CE84',
    [IngestLogMessageLevel.WARNING]: '#FF7800',
    [IngestLogMessageLevel.ERROR]: '#D6001C',
};
