import { jsx as _jsx } from "react/jsx-runtime";
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
export const NotificationCountTooltip = ({ createdAt, }) => {
    if (Math.abs(differenceInMinutes(createdAt, new Date())) > 15) {
        return null;
    }
    return (_jsx(Tooltip, { arrow: true, title: _jsx(Typography, { variant: "body2", children: "The notification value can take several minutes to finalise after a broadcast is sent." }), children: _jsx(InfoOutlined, { sx: { fontSize: 20 }, color: "info" }) }));
};
