import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import xss from 'xss';
import { LabelAndValue } from '../../../components/label-and-value';
import { ButtonContainer, useSelectedBroadcastTemplates } from './shared';
export const ConfirmBroadcastDetails = ({ control, templates, isError, isLoading, }) => {
    const selectedBroadcastTemplates = useSelectedBroadcastTemplates({
        control,
        templates,
    });
    const { getValues } = useFormContext();
    const values = getValues();
    return (_jsxs(Stack, { spacing: 3, paddingY: 3, height: "100%", children: [isError && (_jsx(Alert, { severity: "error", children: "Sorry something went wrong. Please try again." })), _jsxs(Stack, { spacing: 3, height: "100%", divider: _jsx(Divider, {}), children: [values.type === 'ALL_DEVICES' && (_jsx(Alert, { severity: "warning", children: "You are about to broadcast to ALL devices." })), values.type === 'DEVICES_IN_AREAS' && values.selectMode === 'list' && (_jsx(LabelAndValue, { label: selectedBroadcastTemplates.length > 1
                            ? 'Notification Areas'
                            : 'Notification Area', value: _jsx(Stack, { spacing: 1, children: selectedBroadcastTemplates.map((template) => (_jsx(Typography, { color: "inherit", children: template.name }, template.id))) }) })), _jsx(LabelAndValue, { label: "Title", value: xss(values.title) }), _jsx(LabelAndValue, { label: "Message", value: xss(values.message) }), _jsx(LabelAndValue, { label: "URL for more information", value: values.moreInformationUrl || _jsx("i", { children: "Not entered" }) })] }), _jsx(ButtonContainer, { children: _jsx(LoadingButton, { type: "submit", variant: "contained", loading: isLoading, children: "Send" }) })] }));
};
