import axios from 'axios';
import { getApiEndpoint } from '../utils/fallback';
export function isIngestRulePresentation(value) {
    return [
        'jsonpath',
        'xpath',
        'constant',
        'join',
        'reference',
        'custom',
    ].includes(value?.type);
}
export function isIncidentCollectionIngestRule(value) {
    return 'incidents' in value.collectionMap && 'updated' in value.collectionMap;
}
export const getRules = (state) => axios
    .get(`${getApiEndpoint()}/admin/settings/rules/${state}`)
    .then((res) => res.data);
