import { center, centerOfMass, points } from '@turf/turf';
export const parsePolygonJsonString = (polygonJsonString) => {
    try {
        return JSON.parse(polygonJsonString);
    }
    catch {
        return undefined;
    }
};
const getCenterLatLngOfPolygon = (polygon) => {
    const [lng, lat] = centerOfMass(polygon).geometry.coordinates;
    return {
        lng,
        lat,
    };
};
const getCenterLatLngOfCoordinates = (coordinates) => {
    const [lng, lat] = center(points(coordinates)).geometry.coordinates;
    return { lng, lat };
};
export const getCenterOfPolygons = (polygons) => {
    if (!polygons?.length) {
        return undefined;
    }
    const filtered = polygons.filter((x) => !!x);
    return getCenterLatLngOfCoordinates(filtered.map(({ center }) => [center.lng, center.lat]));
};
export const getPolygonCoordinates = (polygon) => {
    return polygon.geometry.coordinates[0];
};
const getLatLngPathsOfPolygon = (polygon) => {
    return getPolygonCoordinates(polygon).map(([lng, lat]) => ({ lng, lat }));
};
export const parsePolygon = (polygonJsonString) => {
    const polygon = parsePolygonJsonString(polygonJsonString);
    if (!polygon) {
        return undefined;
    }
    return {
        center: getCenterLatLngOfPolygon(polygon),
        paths: getLatLngPathsOfPolygon(polygon),
    };
};
