import axios from 'axios';
import { getApiEndpoint } from '../utils/fallback';
export const login = (code, redirect) => axios
    .post(`${getApiEndpoint()}/admin/users/login`, {
    code,
    redirect,
}, {
    headers: {
        'Content-Type': 'application/json',
    },
})
    .then((res) => res.data);
export const getUser = () => axios.get(`${getApiEndpoint()}/users/me`).then((res) => res.data);
