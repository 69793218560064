import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, TextField, } from '@mui/material';
import { Controller, useFormContext, useWatch, } from 'react-hook-form';
import { validateField } from '../../../utils/form';
import { MAX_BROADCAST_MESSAGE_LENGTH, MAX_BROADCAST_TITLE_LENGTH, } from '../types';
import { NotificationAreaFields } from './notification-area-fields';
import { ButtonContainer } from './shared';
export const CreateBroadcastFields = ({ isLoading, register, templates, }) => {
    const { control, resetField, formState: { errors }, } = useFormContext();
    const watchType = useWatch({
        control,
        name: 'type',
    });
    const watchSelectMode = useWatch({
        control,
        name: 'selectMode',
    });
    const clearDrawnPolygon = useCallback(() => resetField('drawnPolygon'), [resetField]);
    useEffect(() => {
        if (watchType === 'ALL_DEVICES' || watchSelectMode === 'list') {
            clearDrawnPolygon();
        }
    }, [clearDrawnPolygon, watchSelectMode, watchType]);
    const watchTitle = useWatch({
        control,
        name: 'title',
    });
    const watchMessage = useWatch({
        control,
        name: 'message',
    });
    const watchDrawnPolygon = useWatch({ control, name: 'drawnPolygon' });
    return (_jsxs(Stack, { spacing: 3, paddingY: 3, height: "100%", children: [_jsx(Controller, { control: control, name: "type", render: ({ field }) => (_jsxs(FormControl, { children: [_jsx(FormLabel, { id: "broadcast-type-radio-buttons-group", children: "Broadcast type" }), _jsxs(RadioGroup, { ...field, "aria-labelledby": "broadcast-type-radio-buttons-group", children: [_jsx(FormControlLabel, { value: "DEVICES_IN_AREAS", control: _jsx(Radio, {}), label: "Devices in area(s)" }), errors.drawnPolygon && (_jsx(FormHelperText, { error: true, children: errors.drawnPolygon.message })), _jsx(FormControlLabel, { value: "ALL_DEVICES", control: _jsx(Radio, {}), label: "All devices" })] })] })) }), watchType === 'DEVICES_IN_AREAS' && (_jsx(NotificationAreaFields, { isLoading: isLoading, templates: templates })), _jsx(TextField, { ...register('title'), label: "Title", helperText: `${(watchTitle ?? '').length}/${MAX_BROADCAST_TITLE_LENGTH}`, ...validateField(errors, 'title') }), _jsx(TextField, { ...register('message'), label: "Message", multiline: true, rows: 5, helperText: `${(watchMessage ?? '').length}/${MAX_BROADCAST_MESSAGE_LENGTH}`, ...validateField(errors, 'message') }), _jsx(TextField, { ...register('moreInformationUrl'), label: "URL for more information", ...validateField(errors, 'moreInformationUrl') }), _jsx(ButtonContainer, { children: _jsxs(Stack, { direction: "row", gap: 1, children: [watchSelectMode === 'manual' && (_jsx(Button, { variant: "outlined", disabled: !watchDrawnPolygon.length, onClick: clearDrawnPolygon, children: "Clear area" })), _jsx(Button, { type: "submit", variant: "contained", children: "Next" })] }) })] }));
};
