import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingView } from '../../components/loading-view';
import { useAuth } from './context';
export const CompleteLogin = () => {
    const { signInWithAuthCode } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    useEffect(() => {
        (async () => {
            if (code) {
                await signInWithAuthCode(code);
                navigate('/');
            }
        })();
    }, [code, signInWithAuthCode, navigate]);
    return _jsx(LoadingView, { height: "100vh" });
};
