import axios from 'axios';
import { getApiEndpoint } from '../utils/fallback';
export var IngestLogMessageLevel;
(function (IngestLogMessageLevel) {
    IngestLogMessageLevel[IngestLogMessageLevel["INFO"] = 10] = "INFO";
    IngestLogMessageLevel[IngestLogMessageLevel["WARNING"] = 20] = "WARNING";
    IngestLogMessageLevel[IngestLogMessageLevel["ERROR"] = 30] = "ERROR";
})(IngestLogMessageLevel || (IngestLogMessageLevel = {}));
export const getDataLogs = () => axios.get(`${getApiEndpoint()}/admin/logs`).then((res) => res.data);
export const getDataLogSource = (key) => axios
    .post(`${getApiEndpoint()}/admin/logs/source`, { key })
    .then((res) => res.data);
