import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Button, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BroadcastAreaMap, BroadcastAreaPolygon, } from '../../../components/broadcast-area-map';
import { Container } from '../../../components/container';
import { LoadingView } from '../../../components/loading-view';
import { formatDate } from '../../../utils/formatters';
import { parsePolygon } from '../../../utils/geo';
import { useBroadcastTemplates } from '../../create-broadcast/components/shared';
export const BroadcastAreasList = () => {
    const navigate = useNavigate();
    const [selectedArea, setSelectedArea] = useState();
    const { templates, isLoading } = useBroadcastTemplates();
    const selectedAreaPolygon = useMemo(() => selectedArea && parsePolygon(selectedArea.polygon), [selectedArea]);
    return (_jsx(Container, { disableGutters: true, children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 7, children: _jsxs(Stack, { spacing: 3, children: [_jsx(Typography, { variant: "h5", children: "Broadcast areas" }), isLoading ? (_jsx(LoadingView, { paddingTop: 8 })) : (_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Name" }), _jsx(TableCell, { children: "Author" }), _jsx(TableCell, { children: "Created" }), _jsx(TableCell, { children: "Action" })] }) }), _jsx(TableBody, { children: (templates || []).map((row) => (_jsxs(TableRow, { hover: true, onClick: () => setSelectedArea(row), style: {
                                                backgroundColor: row.id === selectedArea?.id ? '#DCDCDC' : undefined,
                                            }, children: [_jsx(TableCell, { children: _jsx(Typography, { variant: "body2", fontWeight: "bold", children: row.name }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: row.createdByUserName }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: formatDate(row.createdAt * 1000) }) }), _jsx(TableCell, { children: _jsx(Stack, { direction: "row", spacing: 2, children: _jsx(Button, { onClick: () => navigate(`/broadcasts/create?initialTemplateId=${row.id}`), children: "Send broadcast" }) }) })] }, row.id))) })] }))] }) }), _jsx(Grid, { item: true, xs: 12, md: 5, children: _jsxs(Stack, { spacing: 3, children: [_jsx(Typography, { variant: "h5", children: "Preview" }), selectedAreaPolygon && (_jsx(BroadcastAreaMap, { center: selectedAreaPolygon?.center, children: _jsx(BroadcastAreaPolygon, { paths: selectedAreaPolygon?.paths }) }))] }) })] }) }));
};
