import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Alert, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getBroadcast } from '../../api/broadcast';
import { BroadcastAreaMap, BroadcastAreaPolygon, } from '../../components/broadcast-area-map';
import { Container } from '../../components/container';
import { LabelAndValue } from '../../components/label-and-value';
import { LoadingView } from '../../components/loading-view';
import { NavBack } from '../../components/nav';
import { NotificationCountTooltip } from '../../components/notification-count-tooltip';
import { formatDate, formatNumber } from '../../utils/formatters';
import { getCenterOfPolygons, parsePolygon } from '../../utils/geo';
import { useFilteredBroadcastTemplates } from '../create-broadcast/components/shared';
export const ViewBroadcast = () => {
    const { id } = useParams();
    const { data, isLoading: broadcastLoading } = useQuery({
        queryKey: ['broadcast', id],
        queryFn: () => getBroadcast(id),
    });
    const { templates, isLoading: templatesLoading } = useFilteredBroadcastTemplates(data?.areaTemplateIds);
    const polygons = useMemo(() => {
        if (data?.polygon) {
            return [parsePolygon(data.polygon)];
        }
        if (templates?.length) {
            return templates.map((template) => parsePolygon(template.polygon));
        }
        return undefined;
    }, [data?.polygon, templates]);
    const polygonsCenter = useMemo(() => {
        if (!polygons) {
            return undefined;
        }
        if (polygons.length === 1) {
            return polygons[0]?.center;
        }
        return getCenterOfPolygons(polygons);
    }, [polygons]);
    const area = useMemo(() => (_jsxs(_Fragment, { children: [_jsx(Typography, { color: "GrayText", children: data?.name }), templates && (_jsx(Box, { mt: 1, ml: 2, children: templates.map((template) => (_jsx(Typography, { color: "GrayText", children: template.name }))) }))] })), [data, templates]);
    const isLoading = templatesLoading || broadcastLoading;
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, {}), _jsx(Container, { children: isLoading ? (_jsx(LoadingView, { height: "100vh" })) : (_jsx(_Fragment, { children: data && (_jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 5, children: _jsxs(Stack, { spacing: 3, paddingY: 3, height: "100%", children: [_jsx(Alert, { severity: "info", children: "Broadcast history details" }), _jsx(_Fragment, { children: _jsxs(Stack, { spacing: 3, height: "100%", divider: _jsx(Divider, {}), children: [data.name && (_jsx(LabelAndValue, { label: "Notification Area", value: area })), _jsx(LabelAndValue, { label: "Title", value: data.title }), _jsx(LabelAndValue, { label: "Message", value: data.message }), _jsx(LabelAndValue, { label: "URL for more information", value: data.moreInformationUrl || _jsx("i", { children: "Not entered" }) }), data.deviceCount !== undefined && (_jsx(LabelAndValue, { label: "Notifications", value: _jsxs(Stack, { component: "span", direction: "row", alignItems: "center", spacing: 0.5, children: [_jsx(Typography, { variant: "inherit", children: formatNumber(data.deviceCount) }), _jsx(NotificationCountTooltip, { createdAt: new Date(data.createdAt * 1000) })] }) })), _jsx(LabelAndValue, { label: "Sent", value: formatDate(data.createdAt * 1000) })] }) })] }) }), _jsx(Grid, { item: true, xs: 12, md: 7, children: _jsx(BroadcastAreaMap, { center: polygonsCenter, children: polygons?.map((polygon) => (_jsx(BroadcastAreaPolygon, { paths: polygon?.paths }))) }) })] })) })) })] }));
};
