import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { SnackbarContext } from './context';
export const SnackbarProvider = ({ children }) => {
    const [message, setMessage] = useState();
    const handleClose = useCallback(() => {
        setMessage(undefined);
    }, []);
    return (_jsxs(SnackbarContext.Provider, { value: {
            showSnackbar: (message) => setMessage(message),
        }, children: [children, _jsx(Snackbar, { open: !!message, autoHideDuration: 5000, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'right' }, children: _jsx(Alert, { severity: "success", sx: { width: '100%' }, children: message }) })] }));
};
