import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Container } from '../container';
export const NAV_CONTAINER_HEIGHT = 48;
export const NavContainer = ({ children }) => {
    return (_jsx(Box, { borderBottom: 1, borderColor: "divider", height: NAV_CONTAINER_HEIGHT, children: _jsx(Container, { sx: {
                display: 'flex',
                height: '100%',
                alignItems: 'center',
            }, children: children }) }));
};
