import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { createBroadcast } from '../../api/broadcast';
import { Container } from '../../components/container';
import { NavBack } from '../../components/nav';
import { SuccessWidget } from '../../components/success-widget';
import { ConfirmBroadcastDetails } from './components/confirm-broadcast-details';
import { CreateBroadcastAreaMap } from './components/create-broadcast-area-map';
import { CreateBroadcastFields } from './components/create-broadcast-fields';
import { useBroadcastTemplates } from './components/shared';
import { createBroadcastFormSchema, CreateBroadcastStep, } from './types';
export const CreateBroadcast = () => {
    const [step, setStep] = useState(CreateBroadcastStep.CREATE);
    const [searchParams] = useSearchParams();
    const initialTemplateId = searchParams.get('initialTemplateId');
    const { templates, isLoading } = useBroadcastTemplates();
    const formMethods = useForm({
        mode: 'all',
        resolver: zodResolver(createBroadcastFormSchema),
        defaultValues: {
            type: 'DEVICES_IN_AREAS',
            selectMode: 'list',
            broadcastTemplateIds: initialTemplateId ? [initialTemplateId] : [],
            drawnPolygon: [],
        },
    });
    const { control, register, handleSubmit } = formMethods;
    const createBroadcasts = useCreateBroadcasts({
        onSuccess: () => setStep(CreateBroadcastStep.COMPLETE),
    });
    const onSubmit = useCallback((formValues) => {
        if (step === CreateBroadcastStep.CREATE) {
            setStep(CreateBroadcastStep.CONFIRM);
        }
        else if (step === CreateBroadcastStep.CONFIRM) {
            createBroadcasts.create(formValues);
        }
    }, [step, createBroadcasts]);
    if (step === CreateBroadcastStep.COMPLETE) {
        return (_jsx(SuccessWidget, { text: "Broadcast notification queued for sending.", returnTo: "/broadcasts" }));
    }
    const previousStep = mapStepToPreviousStep[step];
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, { onClick: previousStep ? () => setStep(previousStep) : undefined }), _jsx(Container, { children: _jsxs(Stack, { paddingTop: 3, children: [_jsx(Typography, { variant: "h5", children: "Create broadcast" }), _jsx(FormProvider, { ...formMethods, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsxs(Grid, { item: true, xs: 12, md: 5, children: [step === CreateBroadcastStep.CREATE && (_jsx(CreateBroadcastFields, { isLoading: isLoading, register: register, templates: templates })), step === CreateBroadcastStep.CONFIRM && (_jsx(ConfirmBroadcastDetails, { control: control, templates: templates, isLoading: createBroadcasts.isLoading, isError: createBroadcasts.isError }))] }), _jsx(Grid, { item: true, xs: 12, md: 7, children: _jsx(CreateBroadcastAreaMap, { control: control, templates: templates }) })] }) }) })] }) })] }));
};
const useCreateBroadcasts = ({ onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { mutateAsync } = useMutation({
        mutationFn: (variables) => createBroadcast(...variables),
    });
    const create = useCallback(async ({ type, selectMode, broadcastTemplateIds, drawnPolygon, title, message, moreInformationUrl, }) => {
        try {
            setIsError(false);
            setIsLoading(true);
            if (type === 'ALL_DEVICES') {
                await mutateAsync([
                    'ALL_DEVICES',
                    'Broadcast to all devices',
                    title,
                    message,
                    undefined,
                    undefined,
                    moreInformationUrl,
                ]);
            }
            else {
                if (selectMode === 'list') {
                    await mutateAsync([
                        'DEVICES_IN_TEMPLATE_AREAS',
                        'Broadcast to template area(s)',
                        title,
                        message,
                        undefined,
                        broadcastTemplateIds,
                        moreInformationUrl,
                    ]);
                }
                else {
                    await mutateAsync([
                        'DEVICES_IN_AREA',
                        'Broadcast to area',
                        title,
                        message,
                        drawnPolygon,
                        undefined,
                        moreInformationUrl,
                    ]);
                }
            }
            onSuccess();
        }
        catch {
            setIsLoading(false);
            setIsError(true);
        }
    }, [onSuccess, mutateAsync]);
    return { create, isLoading, isError };
};
const mapStepToPreviousStep = {
    [CreateBroadcastStep.CREATE]: undefined,
    [CreateBroadcastStep.CONFIRM]: CreateBroadcastStep.CREATE,
    [CreateBroadcastStep.COMPLETE]: CreateBroadcastStep.CONFIRM,
};
