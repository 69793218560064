import { createContext, useContext } from 'react';
export const SnackbarContext = createContext({
    showSnackbar: () => '',
});
export const useSnackbar = () => {
    const ctx = useContext(SnackbarContext);
    if (!ctx) {
        throw new Error('Must be called within <SnackbarProvider />');
    }
    return ctx;
};
