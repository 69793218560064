import { memoize } from 'lodash';
import { mustEnv } from './env';
const apiEndpoint = mustEnv('VITE_API_ENDPOINT');
const apiFallbackEndpoint = mustEnv('VITE_API_FALLBACK_ENDPOINT');
const loginRedirect = mustEnv('VITE_COGNITO_REDIRECT_SIGN_IN_URI');
const loginRedirectFallback = mustEnv('VITE_COGNITO_REDIRECT_SIGN_IN_FALLBACK_URI');
export const getApiEndpoint = memoize(() => {
    if (window.location.hostname.replace(/^admin/, '') ===
        new URL(apiEndpoint).hostname.replace(/^api/, '')) {
        return apiEndpoint;
    }
    return apiFallbackEndpoint;
});
export function getLoginRedirectUri() {
    if (window.location.hostname === new URL(loginRedirect).hostname) {
        return loginRedirect;
    }
    return loginRedirectFallback;
}
