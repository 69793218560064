import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { ButtonBase, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getUser } from '../../api/auth';
import { useAuth } from '../../core/auth';
import { Container } from '../container';
export const HEADER_HEIGHT = 94;
export const Header = () => {
    const { signOut, isLoggedIn } = useAuth();
    return (_jsx("header", { className: css({
            height: HEADER_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#2957B0',
            overflow: 'hidden',
        }), children: _jsxs(Container, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: "h5", color: "white", children: "ADMIN DASHBOARD" }), _jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [isLoggedIn && _jsx(LoggedInEmail, {}), _jsx(ButtonBase, { sx: { color: 'white' }, onClick: signOut, children: "LOG OUT" })] })] }) }));
};
const LoggedInEmail = () => {
    const { data } = useQuery({
        queryKey: ['users', 'me'],
        queryFn: getUser,
    });
    if (!data?.email) {
        return null;
    }
    return (_jsxs(Stack, { direction: "row", spacing: 2, sx: { display: { xs: 'none', md: 'flex' } }, children: [_jsx(Typography, { color: "white", children: data.email }), _jsx(Typography, { color: "white", children: "|" })] }));
};
