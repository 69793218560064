import { format } from 'date-fns';
export const formatDate = (date) => format(new Date(date), "d LLL Y 'at' h:mm aaa");
export const formatNumber = (n) => (n || 0).toLocaleString();
export const formatXml = (xml) => {
    const PADDING = ' '.repeat(2); // set desired indent size here
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;
    xml = xml.replace(reg, '$1\r\n$2$3');
    return xml
        .split('\r\n')
        .map((node) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
            indent = 0;
        }
        else if (node.match(/^<\/\w/) && pad > 0) {
            pad -= 1;
        }
        else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
            indent = 1;
        }
        else {
            indent = 0;
        }
        pad += indent;
        return PADDING.repeat(pad - indent) + node;
    })
        .join('\r\n');
};
