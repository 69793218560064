import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NavContainer } from './nav-container';
export const NavTabs = () => {
    const location = useLocation();
    const value = useMemo(() => Object.keys(routeToLabel).findIndex((route) => location.pathname.startsWith(route)), [location]);
    return (_jsx(NavContainer, { children: _jsx(Tabs, { value: value >= 0 ? value : 0, children: Object.entries(routeToLabel).map(([route, label]) => (_jsx(Tab, { component: Link, label: label, to: route, sx: { textTransform: 'none' } }, route))) }) }));
};
const routeToLabel = {
    '/broadcasts': 'Broadcast',
    '/broadcast-areas': 'Broadcast areas',
    '/logs': 'Data logs',
    '/users': 'Users',
    '/analytics': 'Analytics',
    '/settings': 'Settings',
};
