import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DashboardLayout, DefaultLayout } from './components/layout';
import { SnackbarProvider } from './components/snackbar';
import { AuthProvider, CompleteLogin, RequiresAuth } from './core/auth';
import { BroadcastAreas } from './pages/broadcast-areas';
import { Broadcasts } from './pages/broadcasts';
import { CreateBroadcast } from './pages/create-broadcast';
import { DataLogMessages } from './pages/data-log-messages';
import { DataLogs } from './pages/data-logs';
import { InviteUser } from './pages/invite-user';
import { Settings } from './pages/settings';
import { Rules } from './pages/settings/rules';
import { Users } from './pages/users';
import { ViewBroadcast } from './pages/view-broadcast';
import { Analytics } from './pages/analytics';
const queryClient = new QueryClient();
const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});
function App() {
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(AuthProvider, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(SnackbarProvider, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(CompleteLogin, {}) }), _jsxs(Route, { element: _jsx(RequiresAuth, { children: _jsx(DashboardLayout, {}) }), children: [_jsx(Route, { index: true, element: _jsx(Navigate, { replace: true, to: "/broadcasts" }) }), _jsx(Route, { path: "/broadcasts", element: _jsx(Broadcasts, {}) }), _jsx(Route, { path: "/broadcast-areas", element: _jsx(BroadcastAreas, {}) }), _jsx(Route, { path: "/logs", element: _jsx(DataLogs, {}) }), _jsx(Route, { path: "/users", element: _jsx(Users, {}) }), _jsx(Route, { path: "/analytics", element: _jsx(Analytics, {}) }), _jsx(Route, { path: "/settings", element: _jsx(Settings, {}) }), _jsx(Route, { path: "/settings/rules/:state", element: _jsx(Rules, {}) })] }), _jsxs(Route, { element: _jsx(RequiresAuth, { children: _jsx(DefaultLayout, {}) }), children: [_jsx(Route, { path: "/broadcasts/create", element: _jsx(CreateBroadcast, {}) }), _jsx(Route, { path: "/broadcasts/:id", element: _jsx(ViewBroadcast, {}) }), _jsx(Route, { path: "/logs/:name/:type", element: _jsx(DataLogMessages, {}) }), _jsx(Route, { path: "/users/invite", element: _jsx(InviteUser, {}) })] })] }) }) }) }) }));
}
export default App;
