import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { IngestLogMessageLevel } from '../../../api/data-logs';
import { LoadingView } from '../../../components/loading-view';
import { LogLevelLabel } from '../../../components/log-level-label';
import { formatDate } from '../../../utils/formatters';
export var LogLevelFilter;
(function (LogLevelFilter) {
    LogLevelFilter["ALL"] = "ALL";
    LogLevelFilter["INFO"] = "INFO";
    LogLevelFilter["WARNING"] = "WARNING";
    LogLevelFilter["ERROR"] = "ERROR";
})(LogLevelFilter || (LogLevelFilter = {}));
export const DataLogMessagesTable = ({ isLoading, data, filter, }) => {
    const filteredMessages = useMemo(() => {
        const messages = data?.messages || [];
        if (filter === LogLevelFilter.ALL) {
            return messages;
        }
        return messages.filter((message) => message.level === filterToLogLevel[filter]);
    }, [data, filter]);
    if (isLoading) {
        return _jsx(LoadingView, { paddingTop: 8 });
    }
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { sx: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Log level" }), _jsx(TableCell, { children: "Message" }), _jsx(TableCell, { align: "right", children: "Date (GMT+11)" })] }) }), _jsx(TableBody, { children: filteredMessages.map((row, index) => (_jsxs(TableRow, { children: [_jsx(TableCell, { component: "th", scope: "row", children: row.level && (_jsx(LogLevelLabel, { fontWeight: "bold", level: row.level })) }), _jsx(TableCell, { children: row.message }), _jsx(TableCell, { align: "right", children: formatDate(row.createdAt) })] }, index))) })] }) }));
};
const filterToLogLevel = {
    [LogLevelFilter.INFO]: IngestLogMessageLevel.INFO,
    [LogLevelFilter.WARNING]: IngestLogMessageLevel.WARNING,
    [LogLevelFilter.ERROR]: IngestLogMessageLevel.ERROR,
};
