import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { login } from '../../api/auth';
import { mustEnv } from '../../utils/env';
import { getLoginRedirectUri } from '../../utils/fallback';
import { AuthContext } from './context';
const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
const cognitoDomain = mustEnv('VITE_COGNITO_DOMAIN');
const cognitoClientId = mustEnv('VITE_COGNITO_CLIENT_ID');
const loginRedirect = getLoginRedirectUri();
// Setup axios to inject the access token in the headers
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (token) {
        // @ts-ignore https://github.com/axios/axios/issues/5034
        config.headers = {
            ...config.headers,
            authorization: `Bearer ${token}`,
        };
    }
    return config;
});
export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY));
    const redirectToSignIn = useCallback(() => {
        window.location.href = `${cognitoDomain}/login?client_id=${cognitoClientId}&response_type=code&scope=openid&redirect_uri=${loginRedirect}`;
    }, []);
    const signInWithAuthCode = useCallback(async (code) => {
        const { token } = await login(code, loginRedirect);
        if (token) {
            localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
            setIsLoggedIn(true);
        }
    }, []);
    const signOut = useCallback(() => {
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        setIsLoggedIn(false);
    }, []);
    useEffect(() => {
        axios.interceptors.response.use(undefined, (error) => {
            if (error.response.status === 403) {
                signOut();
            }
            return error.response;
        });
    }, [signOut]);
    return (_jsx(AuthContext.Provider, { value: {
            isLoggedIn,
            redirectToSignIn,
            signInWithAuthCode,
            signOut,
        }, children: children }));
};
