import axios from 'axios';
import { getApiEndpoint } from '../utils/fallback';
export const BROADCAST_TEMPLATES_QUERY_KEY = ['broadcastTemplates'];
export const getBroadcastTemplates = () => axios
    .get(`${getApiEndpoint()}/admin/broadcastTemplates`)
    .then((res) => res.data);
export const getBroadcasts = () => axios.get(`${getApiEndpoint()}/admin/broadcasts`).then((res) => res.data);
export const getBroadcast = (id) => axios
    .get(`${getApiEndpoint()}/admin/broadcasts/${id}`)
    .then((res) => res.data);
export const createBroadcast = (type, name, title, message, coordinates, areaTemplateIds, moreInformationUrl) => axios
    .post(`${getApiEndpoint()}/admin/broadcasts`, {
    type,
    name,
    title,
    message,
    coordinates,
    areaTemplateIds,
    moreInformationUrl,
})
    .then((res) => res.data);
