import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import copy from 'clipboard-copy';
export const CopyCodeButton = ({ code }) => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);
            return () => {
                clearTimeout(timeout);
            };
        }
        return undefined;
    }, [copied]);
    return (_jsx(Button, { variant: "contained", "aria-label": "Copy the code", onClick: async () => {
            await copy(code);
            setCopied(true);
        }, children: copied ? 'Copied' : 'Copy' }));
};
