import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';
import { BROADCAST_TEMPLATES_QUERY_KEY, getBroadcastTemplates, } from '../../../api/broadcast';
export const ButtonContainer = ({ children }) => (_jsx(Box, { display: "flex", justifyContent: "flex-end", flexGrow: 1, children: _jsx(Box, { alignSelf: "flex-end", children: children }) }));
export const useBroadcastTemplates = () => {
    const { data, isLoading } = useQuery({
        queryKey: BROADCAST_TEMPLATES_QUERY_KEY,
        queryFn: getBroadcastTemplates,
    });
    return {
        templates: data?.items,
        isLoading,
    };
};
// TODO improvement: filter server side using broadcastRepository.findBroadcastTemplateByIds
export const useFilteredBroadcastTemplates = (templateIds) => {
    const { templates, isLoading } = useBroadcastTemplates();
    const filteredTemplates = useMemo(() => {
        if (!templates || !templateIds?.length) {
            return undefined;
        }
        return templates.filter((template) => templateIds.includes(template.id));
    }, [templateIds, templates]);
    return {
        isLoading,
        templates: filteredTemplates,
    };
};
export const useSelectedBroadcastTemplates = ({ control, templates = [], }) => {
    const watchBroadcastTemplateIds = useWatch({
        control,
        name: 'broadcastTemplateIds',
    });
    return useMemo(() => watchBroadcastTemplateIds.map((id) => templates.find((template) => template.id === id)), [watchBroadcastTemplateIds, templates]);
};
