import { kinks } from '@turf/turf';
import { z } from 'zod';
export var CreateBroadcastStep;
(function (CreateBroadcastStep) {
    CreateBroadcastStep["CREATE"] = "CREATE";
    CreateBroadcastStep["CONFIRM"] = "CONFIRM";
    CreateBroadcastStep["COMPLETE"] = "COMPLETE";
})(CreateBroadcastStep || (CreateBroadcastStep = {}));
export const MAX_BROADCAST_TITLE_LENGTH = 200;
export const MAX_BROADCAST_MESSAGE_LENGTH = 1200;
export const createBroadcastFormSchema = z
    .object({
    type: z.enum(['DEVICES_IN_AREAS', 'ALL_DEVICES']),
    selectMode: z.enum(['manual', 'list']),
    title: z
        .string()
        .min(1, 'Title is required')
        .max(MAX_BROADCAST_TITLE_LENGTH, `Title must not be greater than ${MAX_BROADCAST_TITLE_LENGTH} characters`),
    message: z
        .string()
        .min(1, 'Message is required')
        .max(MAX_BROADCAST_MESSAGE_LENGTH, `Message must not be greater than ${MAX_BROADCAST_MESSAGE_LENGTH} characters`),
    moreInformationUrl: z
        .string()
        .url('Must be a valid URL starting with http:// or https://')
        .or(z.literal(''))
        .refine((val) => !val || val.startsWith('https://') || val.startsWith('http://'), 'Must be a valid URL starting with http:// or https://'),
    broadcastTemplateIds: z.array(z.string()),
    drawnPolygon: z.array(z.array(z.number())),
})
    .refine((val) => val.type !== 'DEVICES_IN_AREAS' ||
    val.selectMode !== 'list' ||
    val.broadcastTemplateIds.length, {
    message: 'You must select at least 1 notification area',
    path: ['broadcastTemplateIds'],
})
    .refine((val) => val.type !== 'DEVICES_IN_AREAS' ||
    val.selectMode !== 'manual' ||
    val.drawnPolygon.length, {
    message: 'You must draw a notification area',
    path: ['drawnPolygon'],
})
    .refine((val) => {
    if (!val.drawnPolygon.length) {
        return true;
    }
    const k = kinks({
        type: 'Polygon',
        coordinates: [val.drawnPolygon],
    });
    return !k.features.length;
}, {
    message: 'Notification area must not overlap with itself',
    path: ['drawnPolygon'],
});
