import { jsx as _jsx } from "react/jsx-runtime";
import { Alert, Box } from '@mui/material';
import { GoogleMap, useJsApiLoader, } from '@react-google-maps/api';
import { LoadingView } from '../loading-view';
const libraries = ['drawing'];
export const Map = ({ center = DEFAULT_CENTER, children, height = '100%', ...props }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    if (loadError) {
        return (_jsx(Box, { paddingTop: 3, children: _jsx(Alert, { severity: "error", children: "Something went wrong. Please refresh to try again." }) }));
    }
    if (!isLoaded) {
        return _jsx(LoadingView, { height: "100%" });
    }
    return (_jsx(GoogleMap, { options: { streetViewControl: false, mapTypeControl: false }, mapContainerStyle: {
            height,
            width: '100%',
        }, center: center, zoom: 11, ...props, children: children }));
};
const DEFAULT_CENTER = {
    lat: -33.9,
    lng: 151.12,
};
