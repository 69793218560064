import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { deactivateUser, reactivateUser, resendUserInvitation, } from '../../../api/users';
import { useSnackbar } from '../../../components/snackbar';
export const UserActions = ({ user, onChange }) => {
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const deactivate = useCallback(async () => {
        setLoading(true);
        await deactivateUser(user.id);
        await onChange();
        setLoading(false);
        showSnackbar(`${user.email} has been deactivated`);
    }, [onChange, showSnackbar, user]);
    const reactivate = useCallback(async () => {
        setLoading(true);
        await reactivateUser(user.id);
        await onChange();
        setLoading(false);
        showSnackbar(`${user.email} has been reactivated`);
    }, [onChange, showSnackbar, user]);
    const resendInvitation = useCallback(async () => {
        setLoading(true);
        await resendUserInvitation(user.id);
        await onChange();
        setLoading(false);
        showSnackbar(`Invitation has been resent to ${user.email}`);
    }, [onChange, showSnackbar, user]);
    if (loading) {
        return _jsx(CircularProgress, { size: 15, style: { marginLeft: 24 } });
    }
    return (_jsxs(_Fragment, { children: [user.enabled && user.status === 'FORCE_CHANGE_PASSWORD' && (_jsx(Button, { onClick: resendInvitation, children: "Resend invitation" })), _jsx(Button, { onClick: () => (user.enabled ? deactivate() : reactivate()), children: user.enabled ? 'Deactivate' : 'Reactivate' })] }));
};
