import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Map } from '../../../components/map';
import { HEADER_HEIGHT } from '../../../components/header';
import { NAV_CONTAINER_HEIGHT } from '../../../components/nav';
import { InfoWindowF } from '@react-google-maps/api';
export function WatchZoneHeatMap({ geojson }) {
    const [info, setInfo] = useState(null);
    return (_jsx(Map, { onLoad: (map) => {
            map.data.setStyle((feature) => {
                let fillColor = 'red';
                let fillOpacity = 0.1;
                let strokeColor = 'red';
                let strokeWeight = 2;
                let strokeOpacity = 1;
                if (feature.getProperty('stroke')) {
                    strokeColor = feature.getProperty('stroke');
                }
                if (feature.getProperty('stroke-width')) {
                    strokeWeight = parseInt(feature.getProperty('stroke-width'));
                }
                if (feature.getProperty('stroke-opacity')) {
                    strokeOpacity = parseInt(feature.getProperty('stroke-opacity'));
                }
                if (feature.getProperty('fill')) {
                    fillColor = feature.getProperty('fill');
                }
                if (feature.getProperty('fill-opacity')) {
                    fillOpacity = parseFloat(feature.getProperty('fill-opacity'));
                }
                return /** @type {!google.maps.Data.StyleOptions} */ {
                    strokeColor,
                    strokeWeight,
                    strokeOpacity,
                    fillColor,
                    fillOpacity,
                };
            });
            const features = map.data.addGeoJson(geojson);
            setMapBounds(features, map);
            map.data.addListener('click', (event) => {
                const count = event.feature.getProperty('count');
                const area = event.feature.getProperty('area');
                const latLng = event.latLng;
                if (latLng) {
                    setInfo({
                        position: latLng,
                        count,
                        area,
                    });
                }
            });
        }, height: `calc(100vh - ${HEADER_HEIGHT}px - ${NAV_CONTAINER_HEIGHT}px)`, children: info && (_jsx(InfoWindowF, { position: info.position, onCloseClick: () => setInfo(null), children: _jsxs("div", { style: { padding: 8, fontSize: 20 }, children: ["Area: ", info.area, _jsx("br", {}), "Count: ", info.count] }) })) }));
}
function setMapBounds(features, map) {
    const bounds = new google.maps.LatLngBounds();
    for (const feature of features) {
        const geometry = feature.getGeometry();
        if (geometry) {
            geometry.forEachLatLng((latLng) => bounds.extend(latLng));
        }
    }
    map.setCenter(bounds.getCenter().toJSON());
    map.fitBounds(bounds);
}
