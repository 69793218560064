import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from './context';
export const RequiresAuth = ({ children }) => {
    const { isLoggedIn, redirectToSignIn } = useAuth();
    if (!isLoggedIn) {
        redirectToSignIn();
        return null;
    }
    return _jsx(_Fragment, { children: children });
};
