import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { Print } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, IconButton, Link, Stack, Typography, } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getRules } from '../../api/rules';
import { Container } from '../../components/container';
import { LoadingView } from '../../components/loading-view';
import { RulePrintStyles } from './components/common';
import { RulesTable } from './components/rules-table';
function CollectionRules({ collectionRules, }) {
    const rulesPrintRef = useRef(null);
    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1a-content", id: "panel1a-header", children: _jsx(Typography, { children: collectionRules.name }) }), _jsxs(AccordionDetails, { children: [_jsx(ReactToPrint, { trigger: () => (_jsx(IconButton, { "aria-label": "print", style: { float: 'right' }, children: _jsx(Print, {}) })), content: () => rulesPrintRef.current }), _jsxs("div", { ref: rulesPrintRef, children: [_jsx(RulePrintStyles, {}), _jsx(RulesTable, { data: collectionRules }, "rules-table")] })] })] }));
}
export const Rules = () => {
    const { state } = useParams();
    const stateUC = state.toUpperCase();
    const { data, isLoading } = useQuery({
        queryKey: ['rules', state],
        queryFn: () => getRules(stateUC),
    });
    return (_jsx(Container, { children: _jsxs(Stack, { paddingY: 4, spacing: 3, children: [_jsxs(Breadcrumbs, { "aria-label": "breadcrumb", children: [_jsx(Link, { underline: "hover", color: "inherit", href: "/settings", children: "Settings" }), _jsx(Typography, { color: "text.primary", children: stateUC })] }), _jsxs(Typography, { variant: "h2", children: [stateUC, " Transformation Rules"] }), isLoading ? (_jsx(LoadingView, { height: "100vh" })) : (data && (_jsx(_Fragment, { children: Object.entries(data).map(([key, collectionRules]) => (_jsx(CollectionRules, { collectionRules: collectionRules }, key))) })))] }) }));
};
