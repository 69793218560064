import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { inviteUser } from '../../api/users';
import { Container } from '../../components/container';
import { NavBack } from '../../components/nav';
import { SuccessWidget } from '../../components/success-widget';
import { validateField } from '../../utils/form';
import { ButtonContainer } from '../create-broadcast/components/shared';
const inviteUserFormSchema = z.object({
    email: z
        .string()
        .min(1, 'Email is required')
        .email('Must be a valid email address'),
});
export const InviteUser = () => {
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState('');
    const formMethods = useForm({
        mode: 'all',
        resolver: zodResolver(inviteUserFormSchema),
    });
    const { register, handleSubmit, formState: { errors, isSubmitting }, } = formMethods;
    const onSubmit = useCallback(async (formValues) => {
        const res = await inviteUser(formValues.email);
        if (res.status === 200) {
            setCompleted(true);
        }
        else if (res.status === 400) {
            setError(res.data.message);
        }
        else {
            setError('Could not invite user');
        }
    }, []);
    if (completed) {
        return (_jsx(SuccessWidget, { text: "User has been sent an invitation email", returnTo: "/users" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, {}), _jsx(Container, { children: _jsxs(Stack, { paddingTop: 3, children: [_jsx(Typography, { variant: "h5", children: "Invite user" }), _jsx(FormProvider, { ...formMethods, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(Grid, { container: true, spacing: 3, children: _jsx(Grid, { item: true, xs: 12, md: 5, children: _jsxs(Stack, { spacing: 3, paddingY: 3, children: [_jsx(TextField, { ...register('email'), label: "Email", ...validateField(errors, 'email') }), error && _jsx(Alert, { severity: "error", children: error }), _jsx(ButtonContainer, { children: _jsx(LoadingButton, { type: "submit", variant: "contained", loading: isSubmitting, children: "Send invitation" }) })] }) }) }) }) })] }) })] }));
};
