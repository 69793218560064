import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
const paletteItemClass = css({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textWrap: 'nowrap',
});
const paletteSwatchClass = css({
    width: 40,
    height: 40,
    margin: 15,
    borderWidth: 1,
    borderColor: '#444',
});
export function HeatPalette({ palette, total, }) {
    return (_jsxs("div", { className: css({ display: 'flex', flexDirection: 'column' }), children: [palette.map(({ colour, from, to }) => (_jsxs("div", { className: paletteItemClass, children: [_jsx("div", { className: `${paletteSwatchClass} ${css({
                            backgroundColor: colour,
                        })}` }), from, " - ", to] }, colour))), _jsx("div", { className: `${paletteItemClass} ${css({ alignSelf: 'center' })}`, children: _jsxs("strong", { children: ["Total: ", total] }) })] }));
}
