import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef } from 'react';
import { DrawingManager } from '@react-google-maps/api';
import { Controller, useWatch } from 'react-hook-form';
import { BroadcastAreaMap, BroadcastAreaPolygon, } from '../../../components/broadcast-area-map';
import { BROADCAST_AREA_POLYGON_OPTIONS } from '../../../components/broadcast-area-map/broadcast-area-polygon';
import { getCenterOfPolygons, parsePolygon } from '../../../utils/geo';
import { useSelectedBroadcastTemplates } from './shared';
export const CreateBroadcastAreaMap = ({ control, templates, }) => {
    const polygonRef = useRef();
    const selectedBroadcastTemplates = useSelectedBroadcastTemplates({
        control,
        templates,
    });
    const watchType = useWatch({ control, name: 'type' });
    const watchSelectMode = useWatch({ control, name: 'selectMode' });
    const selectedAreaPolygons = useMemo(() => selectedBroadcastTemplates.map((template) => parsePolygon(template.polygon)), [selectedBroadcastTemplates]);
    const centerOfSelectedPolygons = useMemo(() => getCenterOfPolygons(selectedAreaPolygons), [selectedAreaPolygons]);
    const onPolygonComplete = useCallback((poly, onChange) => {
        polygonRef.current = poly;
        const coords = [];
        poly
            .getPaths()
            .getAt(0)
            .forEach((a) => {
            coords.push([a.lng(), a.lat()]);
        });
        // close the loop
        coords.push(coords[0]);
        onChange(coords);
    }, []);
    return (_jsxs(BroadcastAreaMap, { center: centerOfSelectedPolygons, children: [watchSelectMode === 'list' &&
                selectedAreaPolygons.map((polygon, idx) => (_jsx(BroadcastAreaPolygon, { paths: polygon?.paths }, idx))), _jsx(Controller, { control: control, name: "drawnPolygon", render: ({ field: { value, onChange } }) => {
                    if (polygonRef.current && !value.length) {
                        polygonRef.current.setMap(null);
                    }
                    return (_jsx(DrawingManager, { options: {
                            drawingControl: false,
                            drawingMode: watchType === 'ALL_DEVICES' ||
                                watchSelectMode === 'list' ||
                                value.length
                                ? null
                                : google.maps.drawing.OverlayType.POLYGON,
                            polygonOptions: BROADCAST_AREA_POLYGON_OPTIONS,
                        }, onPolygonComplete: (poly) => onPolygonComplete(poly, onChange) }));
                } })] }));
};
