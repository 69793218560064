import { jsx as _jsx } from "react/jsx-runtime";
import { isIncidentCollectionIngestRule, } from '../../../api/rules';
import { GenericRulesTable } from './generic-rules-table';
import { IncidentsRulesTable } from './incidents-rules-table';
export const RulesTable = ({ data }) => {
    if (isIncidentCollectionIngestRule(data)) {
        return _jsx(IncidentsRulesTable, { data: data });
    }
    return _jsx(GenericRulesTable, { data: data });
};
