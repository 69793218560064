import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../../api/users';
import { LoadingView } from '../../../components/loading-view';
import { UserActions } from './user-actions';
const FRIENDLY_STATUSES = {
    CONFIRMED: 'Active',
    FORCE_CHANGE_PASSWORD: 'Invited',
};
export const UsersList = () => {
    const { data, isLoading, refetch } = useQuery({
        queryKey: ['users'],
        queryFn: getUsers,
    });
    if (isLoading) {
        return _jsx(LoadingView, { paddingTop: 8 });
    }
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Email" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Enabled" }), _jsx(TableCell, { children: "Action" })] }) }), _jsx(TableBody, { children: data?.map((user) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: user.email }) }), _jsx(TableCell, { children: FRIENDLY_STATUSES[user.status] ?? user.status }), _jsx(TableCell, { children: user.enabled ? 'Yes' : 'No' }), _jsx(TableCell, { children: _jsx(UserActions, { user: user, onChange: async () => {
                                        await refetch();
                                    } }) })] }, user.id))) })] }) }));
};
