import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, IconButton, } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getDataLogSource } from '../../../api/data-logs';
import { LoadingView } from '../../../components/loading-view';
import { formatXml } from '../../../utils/formatters';
import { CopyCodeButton } from './copy-code-button';
export const SourceDetailDialog = ({ selectedSourceKey, onClose, }) => {
    const isOpen = !!selectedSourceKey;
    const dataLogsQuery = useQuery({
        queryKey: ['dataLogSource', selectedSourceKey],
        queryFn: () => getDataLogSource(selectedSourceKey),
        enabled: isOpen,
    });
    const source = dataLogsQuery.data?.source;
    const formattedSource = useMemo(() => {
        if (!source) {
            return undefined;
        }
        if (isJsonSource(source)) {
            return JSON.stringify(JSON.parse(source), null, 4);
        }
        return formatXml(source);
    }, [source]);
    return (_jsxs(Dialog, { fullWidth: true, closeAfterTransition: true, maxWidth: "lg", open: isOpen, onClose: onClose, TransitionComponent: Fade, children: [_jsxs(DialogTitle, { borderBottom: 1, borderColor: "grey.300", children: ["Source", _jsx(IconButton, { "aria-label": "close", onClick: onClose, sx: {
                            position: 'absolute',
                            right: 8,
                            top: 14,
                        }, children: _jsx(Close, {}) })] }), _jsxs(DialogContent, { children: [dataLogsQuery.isLoading && _jsx(LoadingView, {}), formattedSource && (_jsx("pre", { children: _jsx(DialogContentText, { variant: "body2", component: "code", children: formattedSource }) }))] }), _jsx(DialogActions, { children: source && _jsx(CopyCodeButton, { code: source }) })] }));
};
const isJsonSource = (source) => {
    const withoutSpace = source.trim();
    return ((withoutSpace.startsWith('{') && withoutSpace.endsWith('}')) ||
        (withoutSpace.startsWith('[') && withoutSpace.startsWith(']')));
};
