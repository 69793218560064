import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Alert, Box, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, ListItemText, MenuItem, Radio, RadioGroup, Stack, TextField, } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LoadingView } from '../../../components/loading-view';
import { validateField } from '../../../utils/form';
export const NotificationAreaFields = ({ isLoading, templates }) => {
    const { control, formState: { errors }, } = useFormContext();
    const watchSelectMode = useWatch({
        control,
        name: 'selectMode',
    });
    const renderSelectedNotificationAreas = useCallback((selectedIds) => (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: selectedIds.map((id) => (_jsx(Chip, { label: templates?.find((t) => t.id === id)?.name }, id))) })), [templates]);
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { severity: "info", children: "Draw a notification area manually by using the map and controls on the right or select from the existing ones and review on the map." }), _jsx(Stack, { spacing: 1, border: 1, borderColor: "grey.300", borderRadius: 1, padding: 3, bgcolor: "grey.100", children: isLoading ? (_jsx(LoadingView, { paddingY: 2 })) : (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "selectMode", render: ({ field }) => (_jsxs(FormControl, { children: [_jsx(FormLabel, { id: "select-notification-area-radio-buttons-group", children: "Select Notification Area" }), _jsxs(RadioGroup, { ...field, "aria-labelledby": "select-notification-area-radio-buttons-group", children: [_jsx(FormControlLabel, { value: "manual", control: _jsx(Radio, {}), label: "Draw notification area manually" }), errors.drawnPolygon && (_jsx(FormHelperText, { error: true, children: errors.drawnPolygon.message })), _jsx(FormControlLabel, { value: "list", control: _jsx(Radio, {}), label: "Select notification area from list" })] })] })) }), watchSelectMode === 'list' && (_jsx(_Fragment, { children: templates?.length ? (_jsx(Controller, { control: control, name: "broadcastTemplateIds", render: ({ field }) => (_jsx(TextField, { ...field, ...validateField(errors, 'broadcastTemplateIds'), label: "Notification areas", select: true, SelectProps: {
                                        ...field,
                                        multiple: true,
                                        renderValue: renderSelectedNotificationAreas,
                                        sx: { backgroundColor: 'white' },
                                    }, children: templates.map((template) => (_jsxs(MenuItem, { value: template.id, children: [_jsx(Checkbox, { checked: field.value.indexOf(template.id) > -1 }), _jsx(ListItemText, { primary: template.name })] }, template.id))) })) })) : (_jsx(Alert, { severity: "error", children: "There are no notification areas to select. Please draw a notification area manually." })) }))] })) })] }));
};
